/* eslint-disable */
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useUserContext from "../../../hooks/useUserContext";
import getStoryById from "./getStoryById";
import Spinner from "../../../components/spinner/Spinner";

function StoryPage() {
	const params = useParams();
	const navigate = useNavigate();
	const [fetching, setFetching] = React.useState(false);
	const [story, setStory] = React.useState(null);
	const {
		userState: { accessToken },
	} = useUserContext();

	const fetchStory = async () => {
		try {
			setFetching(true);
			const response = await getStoryById(accessToken, params.storyid);

			if (response.status === "success") {
				setFetching(false);
				setStory(response.data);
			} else {
				setFetching(false);
				navigate("/error");
			}
		} catch (error) {
			setFetching(false);
			navigate("/error");
		}
	};

	React.useEffect(() => {
		fetchStory();
	}, []);

	const renderImages = () => {
		const images = story?.storyImages?.[0]?.storyImages;
		if (!images || images.length === 0) return null;

		return (
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
				{images.map((image, index) => (
					<div key={index} className="relative group">
						<img
							src={image?.small?.url}
							alt={`Story Image ${index + 1}`}
							className="w-full h-56 object-cover rounded-xl shadow-md transition-all transform group-hover:scale-105"
						/>
						<div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-30 opacity-0 group-hover:opacity-100 transition-opacity">
							<span className="text-white text-lg">View Image</span>
						</div>
					</div>
				))}
			</div>
		);
	};

	const renderSection = (title, content) => (
		<section className="mt-6 p-6 bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl shadow-md">
			<h3 className="text-2xl font-semibold text-gray-800">{title}</h3>
			<p className="mt-2 text-lg text-gray-700">{content || "Not provided"}</p>
		</section>
	);

	return (
		<>
			{fetching || story === null ? (
				<Spinner displayText="Fetching..." />
			) : (
				<div className="bg-gradient-to-b from-gray-50 to-gray-200 min-h-screen py-6 relative">
					{/* Background Book Emojis */}
					<div className="absolute inset-0 z-0 overflow-hidden">
						{/* Regular Book Emoji 📚 */}
						{/* <span
							role="img"
							aria-label="Book"
							className="absolute text-5xl opacity-15 animate__animated animate__fadeIn animate__delay-2s"
							style={{
								top: "10%",
								left: "5%",
								transform: "rotate(-30deg)",
							}}
						>
							📚
						</span>
						<span
							role="img"
							aria-label="Book"
							className="absolute text-5xl opacity-20 animate__animated animate__fadeIn animate__delay-3s"
							style={{
								top: "25%",
								left: "60%",
								transform: "rotate(15deg)",
							}}
						>
							📚
						</span>
						<span
							role="img"
							aria-label="Book"
							className="absolute text-5xl opacity-25 animate__animated animate__fadeIn animate__delay-4s"
							style={{
								top: "50%",
								left: "35%",
								transform: "rotate(40deg)",
							}}
						>
							📚
						</span>
						<span
							role="img"
							aria-label="Book"
							className="absolute text-5xl opacity-20 animate__animated animate__fadeIn animate__delay-5s"
							style={{
								top: "75%",
								left: "20%",
								transform: "rotate(70deg)",
							}}
						>
							📚
						</span>
						<span
							role="img"
							aria-label="Book"
							className="absolute text-5xl opacity-10 animate__animated animate__fadeIn animate__delay-6s"
							style={{
								top: "80%",
								left: "80%",
								transform: "rotate(-60deg)",
							}}
						>
							📚
						</span> */}

						{/* Open Book Emoji 📖 */}
						{/* <span
							role="img"
							aria-label="Open Book"
							className="absolute text-5xl opacity-15 animate__animated animate__fadeIn animate__delay-2s"
							style={{
								top: "15%",
								left: "75%",
								transform: "rotate(45deg)",
							}}
						>
							📖
						</span>
						<span
							role="img"
							aria-label="Open Book"
							className="absolute text-5xl opacity-20 animate__animated animate__fadeIn animate__delay-3s"
							style={{
								top: "50%",
								left: "10%",
								transform: "rotate(-25deg)",
							}}
						>
							📖
						</span>
						<span
							role="img"
							aria-label="Open Book"
							className="absolute text-5xl opacity-25 animate__animated animate__fadeIn animate__delay-4s"
							style={{
								top: "65%",
								left: "50%",
								transform: "rotate(20deg)",
							}}
						>
							📖
						</span>
						<span
							role="img"
							aria-label="Open Book"
							className="absolute text-5xl opacity-20 animate__animated animate__fadeIn animate__delay-5s"
							style={{
								top: "80%",
								left: "30%",
								transform: "rotate(60deg)",
							}}
						>
							📖
						</span>
						<span
							role="img"
							aria-label="Open Book"
							className="absolute text-5xl opacity-10 animate__animated animate__fadeIn animate__delay-6s"
							style={{
								top: "70%",
								left: "85%",
								transform: "rotate(-40deg)",
							}}
						>
							📖
						</span> */}
					</div>

					<main className="container mx-auto px-6 lg:px-8 z-10">
						{/* Hero section */}
						<header className="text-center mb-8">
							{/* Animated Blog Title */}
							<h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold text-transparent bg-clip-text bg-success-800 leading-tight animate__animated animate__fadeIn animate__delay-1s z-10">
								{story.blogTitle}
							</h1>

							{/* Author Name with Icon and Fun Styling */}
							<p className="text-lg sm:text-xl text-gray-600 mt-3 font-semibold z-10">
								<span role="img" aria-label="Author" className="text-3xl mr-2">✍️</span>
								by <span className="font-bold text-primary-600">{story.firstName} {story.lastName}</span>
							</p>
						</header>

						{/* Content Sections */}
						<div className="bg-white shadow-md rounded-lg p-6 mb-8 z-10">
							{renderSection("Brief description of your organization", story.orgDescription)}
							{renderSection("How did you use COhatch this year to support your organization?", story.prompts?.support)}
							{renderSection("How many times did you use a COhatch location this year?", story.prompts?.amountUsed)}
							{renderSection("Did COhatch help you create connections, receive funding, or other metrics this year?", story.prompts?.metrics)}
							{renderSection("Details related to networking, funding, and investment", story.prompts?.howWeHelped)}
							{renderSection("Feedback about COhatch and this scholarship", story.prompts?.feedback)}
							{renderSection(
								"Social media link mentioning COhatch",
								<a
									href={story.prompts?.socialMediaLink}
									target="_blank"
									rel="noopener noreferrer"
									className="text-blue-600 underline hover:text-blue-800"
								>
									{story.prompts?.socialMediaLink || "Not provided"}
								</a>
							)}
						</div>

						{/* Images Section */}
						{renderImages()}
					</main>
				</div>
			)}
		</>
	);
}

export default StoryPage;
