import config from "../../../config";
import makeAPIRequest from "../../../services/makeRequest";

const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/impactstory`;

async function getImpactStories(query, accessToken) {
  let url = `${API_ENDPOINT}?true=true`; // Starting URL (this seems unnecessary; you can remove it)
  
  // Iterate over the query keys and build the URL
  Object.keys(query).forEach((key) => {
    url += `&${key}=${query[key]}`; // Correct query string formation
  });

  // Make the API request
  const response = await makeAPIRequest(url, "GET", null, accessToken);
  const jsonResponse = await response.json();

  return jsonResponse;
}

export default getImpactStories;
