/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Pagination from "../../../components/pagination/Pagination";
import { useNavigate } from "react-router-dom";
import { MAGIC_NUMBERS } from "../../../constant";
import { debounce } from "../../../services/util";
import useUserContext from "../../../hooks/useUserContext";
import notify from "../../../services/toast";
import StoryCard from "./StoryCard";
import getImpactStories from "./getImpactStories.js";

function ImpactStoryTimeline() {
	const [stories, setStories] = useState([]);
	const { userState } = useUserContext();
	const navigate = useNavigate();
	const [keyword, setKeyword] = useState(""); // Define the keyword state

	function renderStories(story, index) {
		return <StoryCard key={index} story={story} />;
	}

	function onClick() {
		navigate("add");
	}

	const [pagination, setPagination] = React.useState({
		total: 0,
		page: 0,
		remaining: 0,
		limit: 8,
	});

	const fetchImpactStories = async (accessToken, page, limit, keyword) => {
		try {
			const response = await getImpactStories(
				{ page, limit, keyword }, // Only pass page and limit
				accessToken
			);
	
			if (response.status === "success") {
				const { limit, page, remaining, total, results } = response.data;
				setPagination({ limit, page, remaining, total });
				setStories(results);
			} else if (response.status === "fail") {
				let displayMessage = response.message || "Something went wrong.";
				notify(displayMessage, "error");
			}
		} catch (error) {
			notify("An error occurred while fetching stories.");
		}
	};
	
	const handlePrevFetch = async () => {
		if (pagination.page > 0) {
			setPagination({ ...pagination, page: pagination.page - 1 }); // Decrement page number
		}
	};
	
	const handleNextFetch = async () => {
		if (pagination.page < Math.floor(pagination.total / pagination.limit)) {
			setPagination({ ...pagination, page: pagination.page + 1 }); // Increment page number
		}
	};	

	useEffect(() => {
		fetchImpactStories(userState.accessToken, pagination.page, pagination.limit, keyword);
	}, [pagination.page, keyword]); // Fetch stories whenever the page or keyword changes	
	

	// Handle the input change for the search bar
	const handleSearchChange = (e) => {
		setKeyword(e.target.value);
	};

	return (
		<div className="relative bg-gradient-to-br from-emerald-100 via-white to-emerald-300 py-20 sm:py-4 sm:px-6">
			<div className="absolute inset-0 bg-[url('https://www.transparenttextures.com/patterns/cubes.png')] opacity-10 pointer-events-none"></div>

			<div className="relative mx-auto max-w-7xl px-6 lg:px-8">
				<div className="mx-auto max-w-3xl text-center">
					<h2 className="text-gray-900 text-4xl font-extrabold tracking-tight sm:text-5xl">
						Impact Stories
					</h2>
					<p className="mt-4 text-xl leading-relaxed text-gray-700">
						The Latest Impact Stories from our Nonprofits, Startups, and Members.
					</p>
				</div>

				<div className="mt-8 flex flex-col sm:flex-row justify-center items-center gap-4">
					{/* Search Bar */}
					<input
						type="text"
						name="search"
						placeholder="Search Stories"
						className="w-full sm:w-1/2 max-w-lg border border-gray-300 rounded-lg shadow-sm px-4 py-2 text-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 transition-all duration-300 ease-in-out"
						value={keyword} // Bind input value to keyword state
						onChange={handleSearchChange} // Update keyword state on input change
					/>
					{/* Add Story Button */}
					<button
						className="bg-orange-600 hover:bg-orange-700 text-white font-semibold py-3 px-6 rounded-lg shadow-md transition-transform transform hover:-translate-y-1 hover:shadow-lg duration-300"
						onClick={onClick}
						type="button"
					>
						Add Story
					</button>
				</div>

				{/* Stories Grid Section */}
				<div className="mx-auto mt-16 grid grid-cols-1 gap-y-12 gap-x-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
					{stories.map((story, index) => (
						renderStories(story, index)
					))}
				</div>
			</div>

			{/* Pagination Section */}
			<div className="mt-12 flex justify-center">
				<Pagination
					pagination={pagination}
					next={handleNextFetch}
					prev={handlePrevFetch}
				/>
			</div>
		</div>
	);
}

export default ImpactStoryTimeline;
